<template>
  <div class="box">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)" @submit.native.prevent>
      <table class="detailTable_search" style="width: 100%">
<!--        <tr>-->
<!--          <td class="titleTd width80px">参数</td>-->
<!--          <td class="valueTd">-->
<!--            <el-input v-model="dataForm.orkName" placeholder="请输入参数名称" clearable></el-input>-->
<!--          </td>-->
<!--        </tr>-->

        <td class="titleTd width80px">日期</td>
        <td class="valueTd">
          <div class="block">
<!--            <el-date-picker-->
<!--                v-model="dataForm.time"-->
<!--                type="daterange"-->
<!--                align="right"-->
<!--                unlink-panels-->
<!--                range-separator="至"-->
<!--                start-placeholder="开始日期"-->
<!--                end-placeholder="结束日期"-->
<!--                @change="changeHandler"-->
<!--                :picker-options="pickerOptions">-->
<!--            </el-date-picker>-->

            <el-date-picker
                v-model="dataForm.month"
                type="month"
                value-format="yyyy-MM"
                placeholder="选择月">
            </el-date-picker>

          </div>
        </td>


      </table>
      <div class="wxts_msg_search">
        <div class="f_t">
          <el-button-group :disabled="dataListLoading">
            <el-button @click="currentChangeHandle(1)" type="success" :loading="dataListLoading">查询</el-button>
            <el-button type="warning" @click="exportData()">{{ $t('export') }}</el-button>
<!--            <el-button v-if="isAuth('dataflow:olxareaauthmain:save')" type="primary" @click="addOrUpdateHandle()">新增-->
<!--            </el-button>-->
<!--            <el-button v-if="isAuth('dataflow:olxareaauthmain:delete')" type="danger" @click="deleteHandle()"-->
<!--                       :disabled="dataListSelections.length <= 0">批量删除-->
<!--            </el-button>-->
          </el-button-group>
        </div>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
          prop="areaCode"
          header-align="center"
          align="center"
          label="区域">
      </el-table-column>

<!--      <el-table-column-->
<!--        prop="provinciallevelIndex"-->
<!--        header-align="center"-->
<!--        align="center"-->
<!--        label="省级指标">-->
<!--      </el-table-column>-->

      <el-table-column
          prop="addUserNumber"
          header-align="center"
          align="center"
          label="新增用户数">
      </el-table-column>
      <el-table-column
          prop="valueAddedIncome"
          header-align="center"
          align="center"
          label="增值收入">
      </el-table-column>
      <el-table-column
          prop="publicBusinessIncome"
          header-align="center"
          align="center"
          label="大众业务收入">
      </el-table-column>
      <el-table-column
          prop="developmentUser"
          header-align="center"
          align="center"
          label="5G发展用户">
      </el-table-column>
      <el-table-column
          prop="addUserNumberCount"
          header-align="center"
          align="center"
          label="统计新增用户数">
      </el-table-column>
      <el-table-column
          prop="valueAddedIncomeCount"
          header-align="center"
          align="center"
          label="统计增值收入">
      </el-table-column>
      <el-table-column
          prop="publicBusinessIncomeCount"
          header-align="center"
          align="center"
          label="统计大众业务收入">
      </el-table-column>
      <el-table-column
          prop="developmentUserCount"
          header-align="center"
          align="center"
          label="统计5G发展用户">
      </el-table-column>

<!--      <el-table-column-->
<!--        prop="collectIndex"-->
<!--        header-align="center"-->
<!--        align="center"-->
<!--        label="汇总指标">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="executeTime"-->
<!--        header-align="center"-->
<!--        align="center"-->
<!--        label="时间">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="executeType"-->
<!--        header-align="center"-->
<!--        align="center"-->
<!--        label="执行状态">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="okrType"-->
<!--        header-align="center"-->
<!--        align="center"-->
<!--        label="指标状态">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        fixed="right"-->
<!--        header-align="center"-->
<!--        align="center"-->
<!--        width="150"-->
<!--        label="操作">-->
<!--        <template slot-scope="scope">-->
<!--&lt;!&ndash;          <el-button type="text" size="small" v-if="isAuth('dataflow:olxindicatorstrack:update')" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;          <el-button type="text" size="small" class="del-button" v-if="isAuth('dataflow:olxindicatorstrack:delete')" @click="deleteHandle(scope.row)">删除</el-button>&ndash;&gt;-->
<!--          <el-button type="text" size="small" v-if="isAuth('dataflow:olxindicatorstrack:update')" @click="viewHandle(scope.row.id,'look')">查看</el-button>-->

<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import AddOrUpdate from './add-or-update'
  import { getList , deleteData , selectIndex} from '@/api/dataflow/olxindicatorstrack.js'
  export default {
    data () {
      return {
        dataForm: {
          month: ''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      AddOrUpdate
    },
    activated () {
      //this.dataForm.key =  this.$route.query.key;

      let pageIndex = this.$route.query.pageIndex;
      if(pageIndex) {
        this.pageIndex = parseInt(pageIndex);
      }

      let pageSize = this.$route.query.pageSize;
      if(pageSize) {
        this.pageSize = parseInt(pageSize);
      }

      this.getDataList();
    },
    methods: {
       refresh(){
        // this.$router.push({name:'common_olxindicatorstrack',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
      },
      // 获取数据列表
      getDataList () {
         console.log("我是page")
         console.log(this.pageIndex)
         console.log("我是limit")
         console.log(this.pageSize)
        this.dataListLoading = true
        const params = {
          'page': this.pageIndex,
          'limit': this.pageSize,
          'month': this.dataForm.month,
          'areaCode': this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'

        }
        //debugger
        selectIndex(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.data.list
            this.totalCount = data.data.totalCount
          } else {
            this.dataList = []
            this.totalCount = 0
            this.$message.error(data.msg)
          }
        }).catch((err) => {
            console.error(err)
        }).finally(()=>{
        	this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
        this.refresh()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
        this.refresh()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      exportData(){
        const token = this.$cookie.get('x-tr-mimosa-token') // this.$cookie.get('token')
        // 导出数据
        const time = new Date().getTime()
        const url = this.$http.adornUrl(`/dataflow/olxindicatorstrack/export?` +
            'token=' + token +
            '&time=' + time +
            '&month='+ this.dataForm.month+
            '&areaCode='+ (this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0')
        )
        console.log(url);
        window.location.href = url
      },
      //查看
      viewHandle(id,flag){
        this.addOrUpdateVisible=true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id,flag)
        })
      },
      // 删除
      deleteHandle (row) {
        const ids = row ? [row.id] : this.dataListSelections.map(item => {
          return item.id
        })
        //const names = row ? [row.name] : this.dataListSelections.map(item => {
        //  return item.name
        //})
        
        this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dataListLoading = true
          deleteData(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000
              })
              this.getDataList()
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            console.error(err)
          }).finally(()=>{
          	this.dataListLoading = false
          })
        }).catch(_ => {})
      }
    }
  }
</script>
